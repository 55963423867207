import * as React from 'react';
import _, { throttle } from 'lodash';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { GoogleMapsAutocomplete, GoogleMapsAutocompleteProps } from '../../components/ui';

export interface Address {
  houseNumber: string
  streetName: string
  city: string
  theState: string
  county: string
  zipCode: string
}

export interface Selection extends Address {
  latLng: google.maps.LatLngLiteral,
}

export interface AddressAutocompleteProps extends GoogleMapsAutocompleteProps {
  onSelectAddress: (selection: Selection) => void
}

type ComponentType = 'street_number' | 'route' | 'locality' | 'administrative_area_level_1' | 'administrative_area_level_2' | 'postal_code';
const getAddressComponent = (type: ComponentType, result?: google.maps.GeocoderResult): string => result?.address_components?.find(ac => ac.types.includes(type))?.short_name;

export const AddressAutocomplete = ({ onSelectAddress, ...props }: AddressAutocompleteProps) => {
  const onSelectAddressThrottled = React.useMemo(
    () => throttle(async (address: string) => {
      if (!address || _.isEmpty(address)) {
        onSelectAddress({
          latLng: null,
          houseNumber: '',
          streetName: '',
          city: '',
          theState: '',
          county: '',
          zipCode: '',
        });
        return;
      }
      try {
        const [result] = await geocodeByAddress(address);
        const latLng = await getLatLng(result);

        const getComponent = (type: ComponentType) => getAddressComponent(type, result);

        onSelectAddress({
          latLng,
          houseNumber: getComponent('street_number'),
          streetName: getComponent('route'),
          city: getComponent('locality'),
          theState: getComponent('administrative_area_level_1'),
          county: getComponent('administrative_area_level_2'),
          zipCode: getComponent('postal_code'),
        });
      } catch (err) {
        console.error('Error in handleSelect', err);
      }
    }, 100),
    [onSelectAddress],
  );
  return (
    <GoogleMapsAutocomplete {...props} onAddressChanged={onSelectAddressThrottled} />
  );
};

export default AddressAutocomplete;
