import * as React from 'react';

import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';

const AddressCard = ({
  houseNumber,
  streetName,
  city,
  theState,
  zipCode,
  county,
} = {
    houseNumber: '',
    streetName: '',
    city: '',
    theState: '',
    zipCode: '',
    county: '',
}) => {
  return (
    <Box m={1}>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <TextField disabled required fullWidth value={houseNumber} helperText="House #" />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField disabled required fullWidth value={streetName} helperText="Street name" />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField disabled required fullWidth value={city} helperText="City" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField disabled required fullWidth value={theState} helperText="State" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField disabled required fullWidth value={zipCode} helperText="Zip code" />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField disabled required fullWidth value={county} helperText="County" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default React.memo(AddressCard);
