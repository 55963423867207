import * as React from 'react';

import {
  Backdrop,
  Card,
  CardContent,
  CircularProgress,
  Button,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

import { green } from '@material-ui/core/colors';
import { CheckCircle, Error } from '@material-ui/icons';

import { getTheme } from '../../components/ui';

const theme = getTheme();

const useStyles = makeStyles({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  successIcon: {
    color: green[500],
  },
});

export interface StatusIndicatorProps {
  status: 'none' | 'loading' | 'error' | 'fatal' | 'success'
  message?: string
  children?: React.ReactNode
  onClick?: () => void
}

const StatusIndicator = ({ status = 'none', message, onClick, children }: StatusIndicatorProps) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={status !== 'none'} onClick={onClick}>
      {status === 'loading' && (
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item>
            {message && <Typography variant="body1">{message}</Typography>}
          </Grid>
        </Grid>
      )}
      {(status === 'fatal') && (
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item>
            <Error color="error" fontSize="large" />
          </Grid>
          <Grid item>
            {message && (<Typography variant="body1">{message}</Typography>)}
          </Grid>
        </Grid>
      )}
      {(status === 'error') && (
        <Card>
          <CardContent>
            <Grid container direction="row">
              <Grid container spacing={1} direction="column" justify="center" alignItems="center">
                <Grid item>
                  <Error color="error" fontSize="large" />
                </Grid>
                <Grid item>
                  {message && (<Typography variant="body1">{message}</Typography>)}
                </Grid>
                <Grid item>
                  <Button variant="text" color="secondary" onClick={onClick}>Try again</Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      {(status === 'success') && (
        <Card>
          <CardContent>
            <Grid container direction="column" justify="center" alignItems="center">
              <Grid item>
                <CheckCircle className={classes.successIcon} fontSize="large" />
              </Grid>
              <Grid item>
                {message ? (<Typography variant="body1">{message}</Typography>) : (
                  <>
                    <Typography variant="body1">Your account has been created successfully!</Typography>
                    <Typography>Check your mailbox for a confirmation email.</Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      {children}
    </Backdrop>
  );
};

export default React.memo(StatusIndicator);
