import * as React from 'react';
import { DateTime } from 'luxon';
import LuxonUtils from '@date-io/luxon';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { ApolloError } from 'apollo-client';

import {
  Box,
  Container,
  Card,
  CardContent,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';

import { usePublicDealsQuery, useSignupJoinDealMutation } from '../../apollo/generated';
import { AddressAutocomplete, AddressCard, StatusIndicator } from '../../components/deals';
import { ThemedWrapper } from '../../components/ui';
import { parseQueryString } from '../../utils/helpers';
import '../../components/ui/sidebar/layout.css';

import type { StatusIndicatorProps, Selection } from '../../components/deals';

interface SignupCardProps {
  dealBidderId: string
  showAddressCard?: boolean
}

const SignupCard = ({ dealBidderId, showAddressCard = false }: SignupCardProps) => {
  const { data: dealData, loading: dealLoading, error: dealError } = usePublicDealsQuery({ variables: { dealBidderId } });
  const [signupJoinDeal, signupResult] = useSignupJoinDealMutation();

  const [deal] = dealData?.deals || [];
  const dealBidder = (deal?.bidders || []).find(db => db.id === dealBidderId);

  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [selection, setSelection] = React.useState<Selection>(null);
  const [targetDate, setTargetDate] = React.useState<DateTime>(DateTime.invalid('no value'));
  const [errorMessage, setErrorMessage] = React.useState<false | string>(false);
  const [success, setSuccess] = React.useState(false);

  const { houseNumber = '', streetName = '', city = '', theState = '', county = '', zipCode = '', latLng = null } = selection || {};

  const hasAddressSelection = [houseNumber, streetName, city, theState, zipCode, county].some(str => !!str);

  const getAddressError = (): string | false => {
    if (!houseNumber) {
      return 'House number is required.';
    } else if (!streetName) {
      return 'Street name is required.';
    } else if (!city) {
      return 'City is required.';
    } else if (!theState) {
      return 'State is required.';
    } else if (!zipCode) {
      return 'Zip is required.';
    } else if (!county) {
      return 'County is required.';
    } else if (!latLng?.lat || !latLng.lng) {
      return 'Unable to geocode address.';
    }

    return false;
  }

  const addressError = getAddressError();

  const dealIdError = !dealBidderId ? 'No deal specified' : false;
  const dealBidderError = !dealLoading && !dealBidder ? 'Deal could not be loaded.' : false;
  const passwordError = password && password !== confirmPassword ? 'Passwords must match' : false;
  const requiredFieldError = [firstName, lastName, email, password].some(str => !str) ? 'Required field is missing' : false;

  const formError = dealIdError || dealBidderError || passwordError || requiredFieldError || addressError || false;

  const getFatalError = () => {
    if (!dealBidderId) {
      return `Could not find the page you were looking for.`;
    } else if (!dealBidder && !dealLoading) {
      if (dealError?.message) {
        return `There was an error attempting to retrieve the information you requested: ${dealError?.message}`;
      } else {
        return 'Unable to retrieve the information for the deal you requested.';
      }
    }
    return false;
  };

  const getSignupError = () => {
    const [firstError] = (signupResult?.error?.graphQLErrors || []);
    if (!!signupResult?.called && !signupResult?.loading && signupResult?.error?.message) {
      return `Unable to create your account. ${firstError?.message || signupResult?.error?.message}`;
    }

    return false;
  };

  const fatalError = getFatalError();
  const currentError = fatalError || getSignupError();

  // When the error changes, show the error backdrop.
  React.useEffect(() => setErrorMessage(currentError || false), [currentError])

  const showError = (message: string | false) => setErrorMessage(message);
  const dismissError = () => showError(false);
  const handleSuccess = () => {
    dismissError();
    setSuccess(true);
  };

  const getStatus = (): StatusIndicatorProps['status'] => {
    if (fatalError) {
      return 'fatal';
    } else if (errorMessage) {
      return 'error';
    } else if (dealLoading || signupResult?.loading) {
      return 'loading';
    } else if (success) {
      return 'success';
    }
    return 'none';
  };

  return (
    <ThemedWrapper>
      <Container>
        <Box m={1}>
          <Card>
            <CardContent>
              <form>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <TextField required fullWidth
                      label="First name"
                      autoComplete="given-name"
                      value={firstName}
                      onChange={(ev) => setFirstName(ev.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField required fullWidth
                      label="Last name"
                      autoComplete="family-name"
                      value={lastName}
                      onChange={(ev) => setLastName(ev.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField required fullWidth
                      label="Email"
                      autoComplete="email"
                      type="email"
                      value={email}
                      onChange={(ev) => setEmail(ev.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField required fullWidth
                      label="Password"
                      autoComplete="new-password"
                      type="password"
                      value={password}
                      onChange={(ev) => setPassword(ev.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField required fullWidth
                      label="Confirm password"
                      autoComplete="new-password"
                      type="password"
                      error={!!passwordError}
                      helperText={passwordError || undefined}
                      value={confirmPassword}
                      onChange={(ev) => setConfirmPassword(ev.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <AddressAutocomplete
                      type="search"
                      validationError={(hasAddressSelection ? addressError : undefined)}
                      clearOnBlur required handleHomeEndKeys={false}
                      onSelectAddress={setSelection} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                      <DatePicker
                        label="Ideal service date (optional)"
                        fullWidth
                        clearable
                        disablePast
                        autoOk
                        minDate={DateTime.local().startOf('day').plus({ days: 5 })}
                        value={targetDate.isValid ? targetDate : null}
                        onChange={newDate => setTargetDate(DateTime.isDateTime(newDate) ? newDate : DateTime.invalid('no value'))}
                        format="MM/dd/yyyy"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField fullWidth label="Phone number (optional)" autoComplete="tel-national" type="tel" />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button variant="contained" color="primary"
                      onClick={async () => {
                        if (formError) {
                          showError(formError);
                          return;
                        }

                        try {
                          const result = await signupJoinDeal({
                            variables: {
                              dealBidderId,
                              firstName,
                              lastName,
                              email,
                              password,
                              houseNumber,
                              streetName,
                              city,
                              state: theState,
                              county,
                              zipCode,
                              proposedDate: targetDate.isValid ? targetDate.toISODate() : undefined,
                              lat: latLng?.lat?.toString(),
                              lng: latLng?.lng?.toString(),
                            },
                          });

                          if (result?.errors?.length > 0 && result.errors[0]) {
                            showError(result.errors[0].message);
                          } else {
                            handleSuccess();
                          }
                        } catch (err) {
                          if (err instanceof ApolloError) {
                            // This will be handled in the body of the component.
                          } else {
                            showError((typeof err === 'string' ? err : err?.toString()) || 'Unable to create the account.');
                          }
                        }
                      }}
                    >Schedule Me</Button>
                    <StatusIndicator status={getStatus()} message={fatalError || errorMessage || undefined} onClick={dismissError} />
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Box>
        {showAddressCard && <AddressCard
          houseNumber={houseNumber}
          streetName={streetName}
          city={city}
          theState={theState}
          zipCode={zipCode}
          county={county}
        />}
      </Container>
    </ThemedWrapper>
  );
};

const getDealBidderId = () => {
  const { dealBidderId } = parseQueryString();
  return Array.isArray(dealBidderId) ? dealBidderId[0] : dealBidderId;
};

const Signup = () => (
  <SignupCard dealBidderId={getDealBidderId()} />
);

export default Signup;
